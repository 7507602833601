import HomeConfigScript from './HomeConfigScript.vue';
// import CollectionConfigScript from './CollectionConfigScript.vue';

window.FPI.extension.register("#home-config", {
  mounted(element) {
    window.FPI.extension.mountApp({
      element,
      component: HomeConfigScript
    });
  }
});

// window.FPI.extension.register("#collection-config", {
//   mounted(element) {
//     window.FPI.extension.mountApp({
//       element,
//       component: CollectionConfigScript
//     });
//   }
// });
