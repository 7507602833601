<template>
  <div>
    <div
      v-if="isDesktop && (seoContent?.length > 0 || products?.length > 0)"
      :style="`background-color: ${bgColor}; color: ${textColor};`"
      class="main font-body"
    >
      <div v-if="products?.length > 0" class="config-data-product font-body">
        <div class="seo-content sun-editor-editable" v-html="seoContent"></div>
        <div class="products">
          <div class="product-title">Top Products</div>
          <div class="product-list">
            <table class="table">
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Price(RS)</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="data in products" :key="data">
                  <td>
                    <a
                      class="product-link"
                      :href="data.productLink"
                      target="_blank"
                      >{{ data.name }}</a
                    >
                  </td>
                  <td>
                    <a
                      class="product-link"
                      :href="data.productLink"
                      target="_blank"
                      >Rs. {{ data.price }}</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-else class="config-data font-body">
        <div class="seo-content sun-editor-editable" v-html="seoContent"></div>
      </div>
    </div>
    <div
      v-if="isMobile && seoContent?.length > 0"
      class="main font-body"
      :style="`background-color: ${bgColor}; color: ${textColor};`"
    >
      <div class="seo-content sun-editor-editable" v-html="seoContent"></div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import urlJoin from "url-join";
import { isBrowser } from "browser-or-node";

export default {
  name: "HomeConfigScript",
  data() {
    return {
      seoContent: "",
      bgColor: "",
      textColor: "",
      isDesktop: true,
      isMobile: true,
      currentURL: "",
      baseURL: "",
      products: [],
      customCSS: "",
      refreshCount: 0,
      styleId: null,
    };
  },
  mounted() {
    this.getScriptData();
  },
  methods: {
    injectStyle() {
      var styleElement = document.createElement("style");
      styleElement.setAttribute("id", this.styleId);
      styleElement.innerHTML = this.customCSS;

      document.head.appendChild(styleElement);
    },
    async getScriptData() {
      this.baseURL = window.location.origin;
      this.currentURL = window.location.href;
      const params = this.$route.params;
      let data;
      if (
        this.currentURL.includes("collection") &&
        Object.keys(params).length > 0
      ) {
        this.styleId = "collectionPageCSS";
        const currentUrl = new URL(window.location.href);
        const appendedUrl =
          currentUrl.origin +
          "/ext/onpageseo" +
          "/getCollectionPageConfig" +
          currentUrl.search;
        data = await axios.get(appendedUrl, {
          params: { slug: params.slug },
        });
      }
      if (
        this.currentURL == this.baseURL + "/" ||
        this.currentURL == this.baseURL
      ) {
        this.styleId = "homePageCSS";
        data = await axios.get(
          urlJoin(this.baseURL, "ext/onpageseo/getHomePageConfig")
        );
      }
      if (
        this.currentURL.includes("product") &&
        Object.keys(params).length > 0
      ) {
        const currentUrl = new URL(window.location.href);
        const appendedUrl =
          currentUrl?.origin +
          "/ext/onpageseo" +
          "/getProductPageConfig" +
          currentUrl.search;

        this.styleId = "productPageCSS";
        data = await axios.get(urlJoin(appendedUrl), {
          params: { slug: params.slug },
        });
      }

      let contentStr = data?.data?.seoContent;
      if (contentStr) {
        contentStr = contentStr
          .replace(/<strong>/gi, '<strong style="font-weight:600">')
          .replace(/<em>/gi, '<em style="font-style:italic">');
        this.seoContent = contentStr;
      }

      this.bgColor = data?.data.bgColor;
      this.textColor = data?.data.textColor;
      this.isDesktop = data?.data.isDesktop && !this.isMobSize();
      this.isMobile = data?.data.isMobile && this.isMobSize();
      this.products = data?.data?.products;
      this.customCSS = data?.data?.customCSS || "";
    },
    isMobSize() {
      if (isBrowser) {
        if (window && window.screen.width <= 480) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  watch: {
    $route() {
      this.getScriptData();
    },
    customCSS() {
      this.injectStyle();
    },
  },
};
</script>

<style scoped>
@import "../../node_modules/suneditor/dist/css/suneditor.min.css";
.main {
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.2);
}
.config-data {
  color: unset !important;
  background-color: unset !important;
  width: unset !important;
  height: unset !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: baseline !important;
  padding: 10px 30px;
  font-family: inherit;
  max-width: 1080px;
  min-width: 980px;
  margin: auto;
}
.seo-content {
  color: unset !important;
  background-color: unset !important;
  overflow-wrap: break-word !important;
  overflow: hidden;
  /* line-break: anywhere; */
  width: unset !important;
  height: unset !important;
  text-align: justify !important;
  line-height: normal;
  font-family: inherit;
}
.config-data-product {
  grid-template-columns: 65% 35%;
  display: grid;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: baseline !important;
  font-family: inherit;
  max-width: 1080px;
  min-width: 980px;
  margin: auto;
}
/* .table td {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
} */
.table tr {
  line-height: 2;
}
.products {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  gap: 10px;
  padding-left: 30px;
  max-width: 380px;
  font-family: inherit;
}
.product-list {
  width: 100%;
}
.table {
  width: 100%;
  border-spacing: 2px;
}
.table th,
td {
  text-align: left;
  padding: 6px;
}
.table td {
  display: table-cell;
  vertical-align: inherit;
  font-style: normal;
}

.product-link {
  text-decoration: none;
  color: #000;
}

.sun-editor-editable {
  font-family: unset !important;
  padding: unset !important;
}
.sun-editor-editable * {
  font-family: unset !important;
}
.sun-editor-editable h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-margin-before: unset !important;
  -webkit-margin-after: unset !important;
}
</style>
